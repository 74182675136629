import React from 'react';

function FallbackLoader() {
    return (
        <div className="h-full w-full flex justify-center align-content-center">
            loading...
        </div>
    )
}

export default FallbackLoader;
