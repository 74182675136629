import { useEffect, useState } from "react";

export function useLocalStorage(key,initialValue){
const [value, setValue] = useState(()=>{
    const jsonData = localStorage.getItem(key);
if(jsonData!==null) return JSON.parse(jsonData);

if(typeof initialValue==="function"){
    return initialValue

}else{
    return initialValue;
}
});


useEffect(() => {
    localStorage.setItem(key,JSON.stringify(value))
}, [key,value])

return [value,setValue];
}