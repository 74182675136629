import { createContext,useContext,useState } from "react"
import { toast } from "react-toastify";
import { useLocalStorage } from "../../hooks/localStorage/useLocalStorage"


const ShoppingCartContext = createContext();

export function useShoppingCart(){
    return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({children}){
    const [cartItems, setCartItems] = useLocalStorage("cart",[]);
    
  
    
      const total = cartItems.reduce((total,item)=>{return total+item.total_cost},0);
    

    function getItemQuantity(id){
        return cartItems.find(item=>item.id===id)?.quantity||0;
    }
  
    function addItemToCart(product){
        const {id,productName,image_url,currency,amount} = product;
        setCartItems(currentItem=>{
            if(currentItem.find(item=>item.id===id)==null){
                return [...currentItem,{id,productName,currency,amount:Number(amount),total_cost:Number(amount),image_url,quantity:1}]
            }else{
                return currentItem.map(item=>{
                    if(item.id===id){
                        return {...item, quantity:item.quantity+1,total_cost:item.total_cost+item.amount}
                    }else{
                        return item;
                    }
                })
            }
        })
        toast.success(`Product Added successfully`);
    }

    function decreaseCartQuantity(id){
        setCartItems(currentItem=>{
            if(currentItem.find(item=>item.id===id)?.quantity===1){
                return currentItem.filter(item=>item.id!==id);
            }else{
                return currentItem.map(item=>{
                    if(item.id===id){
                        return {...item, quantity:item.quantity-1,total_cost:item.total_cost-item.amount}
                    }else{
                        return item;
                    }
                })
            }
        })
        toast.success(`Item quantity has been updated`);
    }
    function removeFromCart(id){
        setCartItems(currentItem=>{
            return currentItem.filter(item=>item.id!==id);
        });
        toast.success(`Product was removed from cart`);
        
    }
    

    return <ShoppingCartContext.Provider 
    value={{addItemToCart,decreaseCartQuantity,
        removeFromCart,cartItems,getItemQuantity,total,setCartItems
    }}>
        {children}

    </ShoppingCartContext.Provider>

}