import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function ErrorFallback({location}) {
    //const navigate = useNavigate();
    //const fallbackUrl = location==='dashboard'?'/merchant/dashboard':'/';
  return (
    <div className='flex flex-col justify-center items-center h-screen leading-7'>
        <h2 className='text-2xl font-semibold'>Something went wrong</h2>
        <p className='text-gray-600'>We'll get this fix asap.</p>
        

    </div>
  )
}

export default ErrorFallback