export const resFormatter = (res) => {
  console.log(res);
  const readProperty = res?.status ?? res?.code;
  switch (readProperty) {
    case "ERR_NETWORK":
      return "Network Error";
    case 201:
      return res.statusText;
    case 400:
      const objKeys = res.data?.error ?? Object.keys(res.data.message);
      if (typeof objKeys === "string") {
        return objKeys;
      }
      return res.data.message[objKeys[0]][0];
    case 401:
      return res.data.ResponseMessage ?? res.data.message;
    case 500:
      return "An error occured";
    default:
      return "An error occured";
  }
};
