// ---------- import external dependencies ---------
import React from "react";
import { ToastContainer,Zoom,Slide } from "react-toastify";
// Add css file to allow toast display
import "react-toastify/dist/ReactToastify.css";

function Toaster() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        transition={Slide}
        rtl={false}
        pauseOnHover={false}
      >
        {}
      </ToastContainer>
    </>
  );
}

export default Toaster;
