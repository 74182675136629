import { SendInvite, Shirt, TopBadge } from "components/vectors"

export const Questions = [
    {
        title:'What is Mudala',
        text:`Mudala is a social commerce platform that allows you mechants set up, 
        manage and grow your business online using functional tools such as an online store, connecting social media store to online store, payment processing to accept online payments from anywhere in the world, delivery 
        management system to track delivery to any part of the world and much more.`
    },
    {
        title:'What is Escrow service?',
        text:`Escrow service (EASE-API) is just simply a payment protection service 
        to ensure that when buying or selling online nobody gets scammed or is a victim of
         fraud. Mudala Enables merchants to boost sales by offering a 
        risk free shopping experience to their buyers and buyers to shop securely.`
    },
    {
        title:'What Can I do on Mudala?',
        text:`With Mudala you can get a free online store for your business. You also get a fully integrated delivery system for your products, 
        and payment options for your products. Another amazing thing you can do on Mudala is to connect your Facebook and Instagram easily,
         so you don’t ever lose a sale!`
    },
]

export const referralPackage = [
{
    title:'Send Invites',
    description:'Copy your unique link below and send it to your friends and family',
    icon:<SendInvite/>
},
{
    title:'Make Top 3',
    description:'Share with as many people as you can to be among the top 3 referee daily',
    icon:<TopBadge/>
},
{
    title:'Get Mudala Merch',
    description:'You stand a chance to win any of our cool swags and merch as a top referee',
    icon:<Shirt/>
},

]