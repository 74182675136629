import React from 'react'

const TopBadge = ({width="21",height="26" }) => (
    <svg width={width} height={height} viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 11.8199V17.7817C1 19.9648 1 19.9648 3.11111 21.3563L8.91667 24.6311C9.78811 25.1229 11.2119 25.1229 12.0833 24.6311L17.8889 21.3563C20 19.9648 20 19.9648 20 17.7817V11.8199C20 9.63669 20 9.63669 17.8889 8.24521L12.0833 4.97043C11.2119 4.47861 9.78811 4.47861 8.91667 4.97043L3.11111 8.24521C1 9.63669 1 9.63669 1 11.8199Z" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M17.2513 7.75349V4.59866C17.2513 2.19955 16.0239 1 13.5691 1H7.43217C4.97739 1 3.75 2.19955 3.75 4.59866V7.66952" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.2715 11.7846L11.9711 12.8523C12.0816 13.0202 12.3271 13.1881 12.5112 13.2361L13.7631 13.548C14.5364 13.7399 14.745 14.3877 14.2418 14.9875L13.4194 15.9591C13.2967 16.115 13.1985 16.3909 13.2108 16.5829L13.2844 17.8424C13.3335 18.6221 12.7689 19.018 12.0325 18.7301L10.8296 18.2622C10.6455 18.1903 10.3387 18.1903 10.1546 18.2622L8.95174 18.7301C8.21531 19.018 7.65071 18.6101 7.6998 17.8424L7.77345 16.5829C7.78572 16.3909 7.68753 16.103 7.56479 15.9591L6.74244 14.9875C6.23921 14.3877 6.44786 13.7399 7.22112 13.548L8.47306 13.2361C8.66944 13.1881 8.91492 13.0082 9.01311 12.8523L9.71272 11.7846C10.1546 11.1369 10.8419 11.1369 11.2715 11.7846Z" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export default TopBadge