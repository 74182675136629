// ----------- import external dependencies ------------
import styled from "styled-components";

// ------------ import internal dependencies -----------
import { ReactComponent as SearchIcon } from "../../assets/img/svgs/search.svg";

function SearchInput({onChange,placeholder="Search"}) {
  return (
    <>
      <span id="search" className="hidden">
        {placeholder}
      </span>
      <SearchWrapper>
        <SearchIcon />
        <input

          type="text"
          aria-labelledby="search"
          className="input-control search__input h-11"
          placeholder={placeholder}
          onChange={onChange}
        />
      </SearchWrapper>
    </>
  );
}

export default SearchInput;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid var(--grey300);
  padding: 0 .5rem;
  border-radius: 8px;

  & input {
    border: none;
    outline: 0;
    margin: 0;
  }
`;
