import { ArrowRight } from 'components/vectors'
import React from 'react'
import { Link } from 'react-router-dom'
import shopBanner from '../../assets/img/shop-banner.png'

function MobileStoreCard({store_banner,store_name,store_description,merchant_code}) {
  
    return (
        <div className='flex justify-between items-center md:gap-3 cursor-pointer h-auto my-2 md:my-0 md:rounded-none md:border-0' 
        >
            <section className='flex gap-1 md:gap-3 items-center'>
            <div className='image__container w-[90px] md:w-28 h-[72px] md:h-[101px] bg-gray-50 border__sm__mobile'>
                <img src={store_banner}
                    className='w-[90px] md:w-[150px] md:h-[101px] h-[72px] object-cover border__sm__mobile '/>
            </div> 
        <div className='store__information leading-6 md:leading-8 px-2 py-2 mt-2 md:mt-0 flex flex-col'>
            <h3 className='text-md md:text-xl text-[#333333] '>{store_name}</h3>
                <p className='text-[9px] md:text-xs text-[#333333]'>
                        {store_description?.substring(0,30)}
                        
                </p>
              
        </div>
            </section>
                    <p className='px-2'><Link 
                        className='text-[#7F56D9] text-base'
                        to={`/buyer/merchant-store?code=${merchant_code}`}>
                            <ArrowRight />
                        </Link>
                    </p>
                </div>
  )
}

export default MobileStoreCard