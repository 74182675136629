import React from 'react'
import { Link } from 'react-router-dom'

const ProductCategoryHeader = ({title,link}) => {
  return (
    <div className='px-5 md:px-20 h-14 md:h-20 flex justify-between items-center bg-[#AFD2E9]'>
        <p className='text-base md:text-[28px] leading-8 text-[#333333] font-extrabold'>{title}</p>
        <Link className='text-base text-[#7F56D9]' to={link}>See all</Link>
    </div>
  )
}

export default ProductCategoryHeader