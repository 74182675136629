import SearchInput from 'components/SearchInput'
import React, { useState } from 'react'
import { Popover } from "react-tiny-popover";
import FilterPill from "components/FilterPill";
import BuyerLayout from 'layouts/BuyerLayout';
import { FilterIcon } from 'components/vectors';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useStore from 'hooks/store/useStore';
import { useEffect } from 'react';
import StoreCard from 'components/StoreCard';
import Loader from 'components/Loader';
import Pagination from 'components/pagination';
import MobileStoreCard from 'components/MobileProductCard';
import MarketplaceLayout from 'layouts/MarketplaceLayout';
import { queryParams } from '../../../hooks/store/queryparam';
import useCategory from 'hooks/category/usecategory';

const Search = () => {
    const navigate = useNavigate();
    const {searchStores} = useStore();
    const { getStoreCategories } = useCategory();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [totalCount, setTotalCount] = useState(0);
    const [productType, setProductType] = useState('All');
    const [stores,setStores] = useState([]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const keyword = searchParams.get('q');
    queryParams.keyword=keyword
 
    const { isLoading, data:searchResult } = searchStores;
    const { isLoading:loadingCategories, data:categories} = getStoreCategories;

    const filterProducts = (type)=> {
        // queryParams.productsType = type=='All'? '':type;
        // // queryParams.searchProducts = searchTerm;
        // getProducts.refetch()
      }
    
      const filters = categories?.data?.storeCategories?.map((item=>item.categoryname));
    
      const handleFilter = (type)=> {
        // setProductType(type === 'All' ? 'All' : type);
        // filterProducts(type);
        navigate(`/marketplace/${type}`)
        setIsPopoverOpen(false);
      }
     
    useEffect(()=>{
        searchStores.refetch();
    },[keyword])

    useEffect(()=>{
        if(searchResult){
    setStores(searchResult?.data?.stores?.data);
    const {current_page,total,per_page} = searchResult?.data?.stores;
    setCurrentPage(current_page);
    setTotalCount(total);
    setPageSize(per_page)
        }
    },[searchResult])

  return (
    <MarketplaceLayout>
        <main className='md:py-7 px-6 md:px-20  h-auto w-full mt-3 '>
         <section className='flex justify-between flex-col-reverse md:flex-row'>
         {
            stores.length>0?(
                <p className='font-bold text-lg mt-3 md:mt-0'>{`${stores.length} result found for ${keyword}`}</p>
            ):(null)
         }
         <div className='md:hidden search flex gap-3 md:w-2/5 w-full justify-end'>
           <div className='w-full md:w-4/5 '>
           <SearchInput placeholder='Search mudala'  />
           </div>
           <div className='filter'>
           <div className="flex justify-between flex-wrap">
      <div className="flex gap-4 xs:mb-4 md:mb-0">
          {/* <FilterPill title={productType} /> */}
          <Popover
            isOpen={isPopoverOpen}
            // isOpen={isPopoverOpen}
            positions={['bottom']} // if you'd like, you can limit the positions
            padding={10} // adjust padding here!
            reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
            onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
            content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
              <main className="flex flex-cols">
               <div className="position-relative h-80 overflow-auto" style={{background: 'white', border: '1px solid var(--grey300)', padding: '10px',marginRight:'12rem', borderRadius: '8px',boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.1)' }}>
                {filters.map((filter, ind) => (
                  <div key={ind} 
                  className=" border border-b-2 text-base m-2 cursor-pointer hover:text-purple-600 " 
                  onClick={()=>{handleFilter(filter)}}>{filter}</div>
                  
                  
                ))}
              </div>
             
              </main>
            )}>
              <div 
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              className='bg-purple-600 cursor-pointer flex items-center justify-center w-14 h-11  filter-icon'>
              <FilterIcon />
              </div>
            {/* <FilterComponent  onClick={() => setIsPopoverOpen(!isPopoverOpen)} /> */}
          </Popover>
        </div>
        
      </div>
           </div>
           </div>
         </section>
           <section className='mt-2 md:mt-5'>
            
            {
                isLoading?(
                    <div className='flex flex-col items-center justify-center my-auto h-80'><Loader/></div>
                ):(
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10 mt-5 '>
            {
              stores.length>0?(
                stores.map(item=>(
                  <>
                  <div className='hidden md:block'>
                    <StoreCard 
                  store_banner={item?.store?.store_banner}
                  store_description={item?.store?.store_description}
                  merchant_code={item?.merchant_code}
                  store_name={item?.store?.store_name} />
                    </div>
                    <div className='md:hidden'>
                    <MobileStoreCard 
                  store_banner={item?.store?.store_banner}
                  store_description={item?.store?.store_description}
                  merchant_code={item?.merchant_code}
                  store_name={item?.store?.store_name} />
                    </div>
                  </>
                ))
              ):(<p className='text-gray-400 text-center text-lg font-normal col-span-3'>{`No result found for ${keyword}`} </p>)
            }
            
            </div>
                )
            }
            <div className='mt-5'>
            <Pagination 
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={pageSize}
            siblingCount={1}
            onPageChange={page => searchStores(keyword,page)}
       />
            </div>
           </section>
    </main>
    </MarketplaceLayout>
  )
}

export default Search