// ----------- import external dependencies -----------
import { useQuery } from "react-query";

// ---------- import internal dependencies -----------
import { instance } from "../../api/instance";

function useCategory() {
  /**
   * get stores categories
   */
  const getStoreCategories = useQuery(
    "getStores",
    async () => {
      return instance({
        url: "/getStoreCategories",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    }
  );

  const getProductCategories = useQuery(
    "getProducts",
    async () => {
      return instance({
        url: "/getProductCategories",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    }
  );

  return {
    getStoreCategories,
    getProductCategories,
  };
}

export default useCategory;
