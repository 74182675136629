import ProductCategoryHeader from 'components/ProductCategoryHeader'
import StoreCard from 'components/StoreCard'
import React from 'react'

const FeaturedStore = ({stores}) => {
  return (
    <main className='pt-5'>
              <ProductCategoryHeader title="Featured Store" link="/featured-stores" />
              <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10 my-2 md:my-10 px-6 md:px-20'>
              {
              stores?.data?.stores?.data?.length>0?(
                stores?.data?.stores?.data?.map(item=>(
                  <StoreCard 
                  store_banner={item?.store?.store_banner}
                  store_description={item?.store?.store_description}
                  merchant_code={item?.store?.merchant_code}
                  store_name={item?.store?.store_name} />
                ))
              ):(<p className='text-center text-2xl font-semibold col-span-3'>Featured store will display here </p>)
            }
            </div>
            
            </main>
  )
}

export default FeaturedStore
