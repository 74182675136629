import React from 'react';
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// ------------ import assets ------------
// import BrandLogo from "../../../assets/img/logo.png";
// import ShareIcon from "../../../assets/img/svgs/share.svg";
import SearchIcon from "../../../assets/img/svgs/search-dark.svg";
// import UserIcon from "../../../assets/img/svgs/user.svg";
// import CartIcon from "../../../assets/img/svgs/cart.svg";

function BuyerFooter() {
    const navigate = useNavigate();
    return (
        // <div className="w-full mt-[20%]">
        <div className="w-full mt-[30vh]">
            
            <div className="w-full">
                <ThinLine />
            </div>
            <div className="md:py-8 py-3 text-center  md:px-20 flex md:justify-between justify-center flex-col-reverse md:flex-row gap-4">
                
                
                    <div className="">
                        <small className="text-grey-600 text-[15px] w-full ">Copyright © 2022 Mudala</small>
                    </div>
                    <div className="flex justify-center md:justify-end gap-5 text-[15px] w-full md:w-1/2 mt-10 md:mt-0">
                        <Link className="text-grey-600" to="#">Privacy Policy</Link>
    
                        <Link className="text-grey-600" to="#">Terms of Service</Link>
                        <Link className="text-grey-600" to="/buyer/support">Support</Link>
                    </div>
                    
               
            </div>
        </div>
    )
}

const ThinLine = styled.div`
  background-color: var(--grey200);
  height: 1px;
  width: 100%;
  margin-top: 10px;
`;

export default BuyerFooter;
