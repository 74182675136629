// ----------- import external dependencies ------------
import styled from "styled-components";

// ---------- import internal dependencies -------------
import { ReactComponent as CloseIcon } from "../../assets/img/svgs/close.svg";

function FilterPill({ title }) {
  return (
    <>
      <PillWrapper>
        <span>{title}</span>
        <CloseIcon />
      </PillWrapper>
    </>
  );
}

export default FilterPill;

const PillWrapper = styled.div`
  display: flex;
  min-height: 40px;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: var(--purple50);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: var(--purple700);
  font-size: 0.8rem;

  & svg path {
    stroke: var(--purple700);
  }
`;
