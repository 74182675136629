// ------------- import external dependencies ------------
import styled from "styled-components";
import PropTypes from "prop-types";

// --------------- import internal dependencies -----------------
import Seo from "../../shared/Seo";
import { sizes } from "../../utils/screenSizes";
// ------ import images -------
import AuthBg from "../../assets/img/auth-bg.png";

function AuthLayout({ page, children, imgDesc = "Authentication banner" }) {
  return (
    <>
      <Seo page={page} allowSkip>
        <main>
          <section>
            <Wrapper>
              <div>{children}</div>
              <div className="md:hidden lg:block ">
                <img src={AuthBg} alt={imgDesc} className="h-screen w-full " />
              </div>
            </Wrapper>
          </section>
        </main>
      </Seo>
    </>
  );
}

AuthLayout.propTypes = {
  page: PropTypes.string,
  children: PropTypes.node,
  imgDesc: PropTypes.string,
};

export default AuthLayout;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;

  & > div {
    width: 50%;

    @media screen and (max-width: ${sizes.mobileL}) {
      width: 100%;

      &:nth-child(2n) {
        display: none;
      }
    }
  }
`;
