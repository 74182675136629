import React from 'react';
import { usePagination, DOTS } from '../../hooks/pagination/usePagination';

const Pagination = props => {
    const active = 'border-indigo-500 bg-indigo-50 text-indigo-600';
    const inActive = 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50'
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
  
 

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if(currentPage>1){
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
  <div className="flex flex-1 justify-between sm:hidden">
    <a className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
    <a className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
  </div>
  <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700">
        Showing
        <span className="font-medium"> {(currentPage - 1) * 12 + 1} </span>
         to
        <span className="font-medium"> {currentPage * 12 > totalCount ? totalCount : currentPage * 12} </span>
         of
        <span className="font-medium"> {totalCount} </span>
         results
      </p>
    </div>
    <div>
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <a 
        onClick={onPrevious}
        disabled={currentPage == 1? 'disabled':''}
         className={`${currentPage===1?'cursor-not-allowed':'cursor-pointer'} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 `}>
          <span className="sr-only">Previous</span>
         
          <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
          </svg>
        </a>
       
        {paginationRange.map(pageNumber => {
           
            if (pageNumber === DOTS) {
                <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">...</span>
              }
              return (
                <a 
                onClick={() => onPageChange(pageNumber)}
                
                    className={`${pageNumber === currentPage?active:inActive} relative z-10 inline-flex items-center border px-4 py-2 text-sm font-medium  focus:z-20 cursor-pointer`}>
                {pageNumber}
                    </a>
                    );

        }
        )}


        
        <a 
        onClick={onNext}
        disabled={currentPage === lastPage? 'disabled':''}
        className={`${currentPage === lastPage?'cursor-not-allowed':'cursor-pointer'} relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 `}>
          <span className="sr-only">Next</span>
        
          <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
          </svg>
        </a>
      </nav>
    </div>
  </div>



  
</div>
    
  );
};

export default Pagination;