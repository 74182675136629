import React from 'react'

const RocketIcon = () => (
    <svg width="470" height="481" viewBox="0 0 470 481" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="235" cy="245.59" r="235" fill="#7F56D9"/>
<path d="M203.945 324.603C194.02 340.731 174.362 364.97 141.535 372.699L149.074 347.887C149.074 347.887 119.968 367.259 92.0078 372.699C97.4474 344.643 116.82 315.633 116.82 315.633L92.0078 323.172C99.7369 290.345 123.976 270.686 140.103 260.762L208.821 246.332L203.945 324.603Z" fill="#F2C94C"/>
<path d="M312.509 390.593C276.654 426.448 219.246 427.584 182.032 394.015C196.722 392.458 212.558 380.774 214.774 360.212C215.697 351.671 214.261 341.593 209.414 330.071L134.631 255.288C123.109 250.441 113.031 249.005 104.49 249.928C83.9284 252.144 72.2436 267.979 70.687 282.669C37.1189 245.456 38.253 188.049 74.1091 152.192C95.3117 130.989 124.069 121.931 151.723 125.021C164.403 126.44 176.848 130.398 188.23 136.928L327.772 276.47C334.303 287.852 338.268 300.303 339.679 312.977C342.771 340.632 333.711 369.39 312.509 390.593Z" fill="#EB5757"/>
<path d="M339.682 312.979C302.541 337.785 260.064 353.428 214.776 360.211C215.699 351.671 214.262 341.592 209.416 330.071L134.632 255.287C123.111 250.441 113.033 249.005 104.492 249.928C111.276 204.639 126.92 162.162 151.724 125.021C164.404 126.44 176.849 130.399 188.231 136.929L327.773 276.47C334.305 287.855 338.27 300.304 339.682 312.979Z" fill="#C40606"/>
<path d="M209.416 330.073C267.624 316.704 322.904 287.356 368.251 242.01C434.85 175.411 466.954 87.3849 464.583 0.122247C377.321 -2.24822 289.295 29.8548 222.695 96.4541C177.349 141.801 148.002 197.081 134.633 255.289L209.416 330.073Z" fill="#E3E8EC"/>
<path d="M260.517 134.279C215.174 179.622 179.083 228.17 154.066 274.72L134.633 255.287C147.994 197.082 177.35 141.799 222.694 96.4557C289.292 29.8556 377.318 -2.24578 464.582 0.126322C400.608 21.0446 327.117 67.68 260.517 134.279Z" fill="#D5D8DB"/>
<path d="M445.116 121.213C425.616 109.499 406.279 94.4704 388.257 76.4496C379.554 67.7473 371.556 58.7475 364.291 49.5746C356.499 39.7307 349.54 29.6826 343.469 19.5912C382.294 5.5588 423.233 -0.929131 464.007 0.109428C464.2 0.121685 464.393 0.121685 464.581 0.127405L464.575 0.133125C465.684 41.0888 459.209 82.207 445.116 121.213Z" fill="#EB5757"/>
<path d="M451.795 281.064C451.789 281.058 451.795 281.051 451.789 281.045L451.795 281.064Z" fill="#EB5757"/>
<path d="M464.579 0.127405L464.573 0.133125C432.822 10.5081 398.722 27.2255 364.291 49.5746C356.499 39.7307 349.54 29.6826 343.469 19.5912C382.293 5.5588 423.232 -0.929131 464.006 0.109428C464.199 0.121685 464.392 0.121685 464.579 0.127405Z" fill="#C40606"/>
<path d="M318.317 207.883C352.28 207.883 379.813 180.351 379.813 146.387C379.813 112.423 352.28 84.8906 318.317 84.8906C284.353 84.8906 256.82 112.423 256.82 146.387C256.82 180.351 284.353 207.883 318.317 207.883Z" fill="#EB5757"/>
<path d="M318.319 187.799C341.19 187.799 359.731 169.258 359.731 146.387C359.731 123.516 341.19 104.975 318.319 104.975C295.447 104.975 276.906 123.516 276.906 146.387C276.906 169.258 295.447 187.799 318.319 187.799Z" fill="#A0D9F2"/>
</svg>

)

export default RocketIcon