import React from 'react'

const Copy = ({width="22",height="22",onClick}) => (
    <svg 
    onClick={onClick}
    width={width} 
    height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.3307 11.7856V15.4523C13.3307 18.5078 12.1641 19.73 9.2474 19.73H5.7474C2.83073 19.73 1.66406 18.5078 1.66406 15.4523V11.7856C1.66406 8.73003 2.83073 7.50781 5.7474 7.50781H9.2474C12.1641 7.50781 13.3307 8.73003 13.3307 11.7856Z" stroke="#7F56D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3307 6.54731V10.214C18.3307 13.2695 17.1641 14.4918 14.2474 14.4918H13.3307V11.7854C13.3307 8.72985 12.1641 7.50763 9.2474 7.50763H6.66406V6.54731C6.66406 3.49175 7.83073 2.26953 10.7474 2.26953H14.2474C17.1641 2.26953 18.3307 3.49175 18.3307 6.54731Z" stroke="#7F56D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


)

export default Copy