import React, {useState, useEffect, useRef} from 'react';
import Cart from '../../../components/Cart';
import { toast } from "react-toastify";

// ------------ import external dependencies ------------
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";

// ------------ import assets ------------
import BrandLogo from "../../../assets/img/logo.png";
import ShareIcon from "../../../assets/img/svgs/share.svg";
import SearchIcon from "../../../assets/img/svgs/search-dark.svg";
import UserIcon from "../../../assets/img/svgs/user.svg";

import {ReactComponent as MenuIcon } from "../../../assets/img/svgs/menu-2.svg";
// ------  custom hooks ------
import useOrder from "../../../hooks/order/useOrder";
import SearchInput from 'components/SearchInput';
import MarketplaceSearchInput from 'components/MarketplaceSearchInput';
import { CartIcon, SupportIcon } from 'components/vectors';
import useCategory from 'hooks/category/usecategory';

function Header({cartData, toggleCart, cart, storeIcon}) {
    const params = useParams();
    const {category} = params;
    const { getStoreCategories } = useCategory();
    const navigate = useNavigate();
    const [menu, toggleMenu] = useState(false);
    const home = localStorage.getItem('home')?localStorage.getItem('home'):'/';
    const { isLoading, data:categories} = getStoreCategories;
    console.log(cartData)
    // checkout from store and proceed to payment
    const checkout = ()=> {
        if (cartData?.length > 0) {
            navigate('/buyer/merchant-store/checkout', {
                state: {
                    cart: cartData
                }
            })
        }
        else {
            toast.info("No items in cart");
        }
    }

    const nav = (route)=> {
        navigate(route);
    }
    let timeout = useRef();
    const handleSearchInput = (event)=> {
      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        navigate(`/search?q=${event.target.value}`)
        //search(event.target.value);
      }, 2000);
    }
    
    return (
        <main>
<div className="py-3 px-5 md:px-20 border-0 border-b border-solid border-[#E3E3E3] flex">
            <div className="w-full md:w-1/4 flex">
                {/* <img src={SampleLogo} alt="sample logo" className="mr-auto text-sm" style={{height: '32px', width: '32px'}} /> */}
                {/* <img src={storeIcon ? storeIcon : BrandLogo} alt="sample logo" className="mr-auto text-sm" style={{height: '30px', width: '60px'}} /> */}
                <img src={storeIcon ? storeIcon : BrandLogo} alt="sample logo" className="mr-auto text-sm object-contain" style={{width: "90.42px",height: "32px"}} />
                <div className="flex items-center gap-2 cursor-pointer md:hidden" onClick={()=>toggleCart(!cart)}>
                <CartIcon />
                       
                        {cartData?.length > 0 ? (
                            <span className="flex p-px bg-red-600 place-content-center text-center justify-center" style={{height: '20px', width: '20px', borderRadius: '10px'}}>
                                <span className="my-auto text-xs text-white-100">{cartData?.length}</span>
                            </span>
                        ) : (null)}
                       
                        
                    </div>
            </div>
            <div className="w-1/2 hidden md:flex">
            <div className='md:mt-0 mt-3 w-full'>
                <MarketplaceSearchInput 
                onChange={handleSearchInput}
                placeholder='Search for stores'  />
                </div>
            </div>
            
            <div className="w-1/2 md:w-1/4 hidden md:flex">
                <div className="ml-auto flex space-x-5 md:space-x-5">
                    <div className="flex items-center gap-2 cursor-pointer" onClick={()=>toggleCart(!cart)}>
                       <CartIcon />
                        <span>Cart
                        {cartData?.length > 0 ? (
                            <span className="flex p-px bg-red-600 place-content-center text-center justify-center" style={{height: '20px', width: '20px', borderRadius: '10px'}}>
                                <span className="my-auto text-xs text-white-100">{cartData?.length}</span>
                            </span>
                        ) : (null)}
                        </span>
                        
                    </div>
                    <Link to="/buyer/support" className='flex items-center gap-2 cursor-pointer normal__link'>
                        <SupportIcon />
                        <p className='text-base'>Support</p>
                    </Link>
                    
                </div>
            </div>

           
        </div>
        <header className='py-3 px-5 md:px-20 border-0 border-b border-solid border-[#E3E3E3] gap-8 hidden md:flex'>
        <Link to="/marketplace" className='text-base font-normal text-[#101828]'>Home</Link>
    {
        categories?.data?.storeCategories?.slice(0,5)?.map(store=>(
                <NavLink 
                
                to={`/marketplace/${store.categoryname}`} 
                className={`text-base font-normal ${category==store.categoryname?'text-purple-600':'text-[#101828]'}`}>{store?.categoryname}</NavLink>
            ))

    }                
                         
        </header>
       
        </main>
        
    )
}

export default Header
