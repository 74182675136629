import { useEffect } from 'react';

// ------------ import external dependencies ------------
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'


// ----------- import internal dependencies -----------
import DashboardLayout from "../layouts/DashboardLayout";
import ErrorFallback from '../screens/ErrorBoundary/DashboardErrorFallback';
import useAuth from '../hooks/user/useAuth';


function DashboardRoutes({ routes }) {
  const {authCheckState} = useAuth();
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    if(!token) {
      logout();
    }
    // }else{
    //   const expirationDate = new Date(localStorage.getItem("expirationDate"));
    //     console.log({expirationDate});
    //     if (expirationDate <= new Date()) {
    //       logout();
    //     }
    // }
  }, [])

  // logs out when token is not found
  const logout = ()=> {
    console.log('logout');
    localStorage.clear();
    navigate('/signin', {replace: true});
  }

  return (
    <>
      <DashboardLayout>
      <ErrorBoundary FallbackComponent={ErrorFallback} >
        <Routes>
          {routes.map((route, ind) => (
            <Route path={route.path} key={ind} element={<route.component />} />
          ))}
          <Route path="*" element={
            <div className="h-full w-full flex justify-center align-content-center">
                <h2>404 Page not found</h2>
            </div>
        } />
        </Routes>
        </ErrorBoundary>
      </DashboardLayout>
    </>
  );
}

DashboardRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default DashboardRoutes;
