import { toast } from 'react-toastify';

/**
 * Handle object conversion to form data
 * @param {object} data
 * @returns
 */
export const jsonToFormData = (data) => {
  let form = new FormData();

  // ------ get keys of data -----
  const objKeys = Object.keys(data);

  // ------- form data values ------
  objKeys.forEach((key) => {
    form.append(key, data[key]);
  });

  return form;
};



export const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "UGX",
  style: "currency",
  minimumFractionDigits: 0,
  signDisplay: "auto",
  currencyDisplay: "narrowSymbol",
});

export const notify = (message,type)=>{
  const customId = "custom-id-yes";
  toast(message, {
    toastId: customId,
    type:type
  });

}

export const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
}  

export const countDownTimer = ()=>{
  // let d='00'
  // let h='00'
  // let m = '00'
  // let s ='00'
  let days;
  let minutes;
  let hours;
  let seconds;
  let countDownDate = new Date("Feb 25, 2023 15:37:25").getTime();

  let interval = setInterval(function() {

    // Get today's date and time
    let now = new Date().getTime();
      
    // Find the distance between now and the count down date
    let distance = countDownDate - now;
      
    // Time calculations for days, hours, minutes and seconds
    days = Math.floor(distance / (1000 * 60 * 60 * 24));
    hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    seconds = Math.floor((distance % (1000 * 60)) / 1000);
      
    // d=days;
    // h=hours;
    // m=minutes;
    // s=seconds
    // If the count down is over, write some text 
    if (distance < 0) {
      clearInterval(interval);
    }
  }, 1000);
  return {
    days,hours,minutes,seconds
  }

}