// ------------ import internal dependencies ------------
import { useState, useEffect } from 'react';

// ------------ import external dependencies ------------
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useNavigate, Navigate } from "react-router-dom";
import {ErrorBoundary} from 'react-error-boundary'
// import { instance } from "../api/instance";
// import { toast } from "react-toastify";

// ------  custom hooks ------
import useOrder from "../hooks/order/useOrder";
import BuyerLayout from '../layouts/BuyerLayout';
import BuyerErrorFallback from '../screens/ErrorBoundary/BuyerErrorFallback';

function BuyerRoutes({ routes}) {
    const [storeIcon, setStoreIcon] = useState(null);

    // initializing custom hooks
    const { getCart } = useOrder();
    const { isLoading: loadingCart, data: cartData, isError: cartLoadingError } = getCart;

    const setIcon = (url) => {
        setStoreIcon(url);
    }

    // reload cart content
    const refetchCart = ()=> {
        getCart.refetch();
    }

  return (
    <div className="relative h-max">
        <BuyerLayout icon={storeIcon}>
        <ErrorBoundary FallbackComponent={BuyerErrorFallback} >
            <Routes>
                {routes.map((route, ind) => (
                    <Route path={route.path} key={ind} element={<route.component setIcon={setIcon} refetchCart={refetchCart} cartData={cartData} />} />
                ))}
                <Route path="/" element={<Navigate to="/buyer/account" />} />
                {/* for all links not found */}
                <Route path="*" element={
                    <div className="h-full w-full flex justify-center align-content-center">
                        <h2>404 Page not found</h2>
                    </div>
                } />
            </Routes>
            </ErrorBoundary>
        </BuyerLayout>
    </div>
  );
}

BuyerRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

// const ThinLine = styled.div`
//   background-color: var(--grey200);
//   height: 1px;
//   width: 100%;
//   margin-top: 10px;
// `;

export default BuyerRoutes;
