import React from 'react';
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// ------------ import assets ------------
// import BrandLogo from "../../../assets/img/logo.png";
// import ShareIcon from "../../../assets/img/svgs/share.svg";
import SearchIcon from "../../../assets/img/svgs/search-dark.svg";
// import UserIcon from "../../../assets/img/svgs/user.svg";
// import CartIcon from "../../../assets/img/svgs/cart.svg";

function BuyerFooter() {
    const navigate = useNavigate();
    return (
        // <div className="w-full mt-[20%]">
        <div className="w-full mt-20">
            <div className="px-6 md:px-20 w-full">
                <ThinLine />
            </div>
            <div className="flex px-6 md:px-40 py-5">
                <div className="mx-auto flex space-x-10">
                    <span className="basis-2 text-md text-grey-600 my-auto cursor-pointer" onClick={()=>navigate('/', {replace: true})}>Home</span>
                    <span className="basis-2 text-md text-grey-600 my-auto">Checkout</span>
                    <span className="basis-2 text-md text-grey-600 my-auto">Support</span>
                </div>
            </div>
            <div className="px-6 md:px-20 w-full">
                <ThinLine />
            </div>
            <div className="py-5 px-6 md:px-40 flex flex-col md:flex-row justify-center">
                <div className="sm:basis-1/2">
                    <div className="input-wrap w-full">
                        <div className="input-control flex px-0">
                            <img src={SearchIcon} alt="sample logo" className="basis-2 my-auto mx-2" style={{height: '20px', width: '20px'}} />
                            <input
                                id="search"
                                type="text"
                                className="border-0 w-full"
                                placeholder="Search"
                            />
                        </div>
                    </div>
                </div>
                <div className="sm:basis-1/2 flex flex-col">
                    <div className="flex ml-0 md:ml-auto gap-2">
                        <span className="text-grey-600">Privacy Policy</span>
                        <span className="text-grey-600">.</span>
                        <span className="text-grey-600">Terms of Service</span>
                    </div>
                    <div className="flex ml-0 md:ml-auto gap-2">
                        <small className="text-grey-600">Copyright © 2022 Mudala</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ThinLine = styled.div`
  background-color: var(--grey200);
  height: 1px;
  width: 100%;
  margin-top: 10px;
`;

export default BuyerFooter;
