// ------------ import external dependencies ------------
import { Routes, Route, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// ----------- import internal dependencies -----------
import AuthLayout from "../layouts/AuthLayout";

function AuthRoutes({ routes }) {
  // ------- intialize custom hooks -------
  const location = useLocation();

  const currentRoute =
    routes.filter((route) => route.path === location.pathname)[0]?.title || "";

  return (
    <>
      <AuthLayout page={currentRoute}>
        <Routes>
          {routes.map((route, ind) => (
            <Route path={route.path} key={ind} element={<route.component />} />
          ))}
           
        </Routes>
        
      </AuthLayout>
      
    </>
  );
}

AuthRoutes.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default AuthRoutes;
