import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

function BuyerErrorFallback() {
  const home = localStorage.getItem('home')?localStorage.getItem('home'):'/';
    //const navigate = useNavigate();
    //const fallbackUrl = location==='dashboard'?'/merchant/dashboard':'/';
  return (
    <div className='flex flex-col justify-center items-center h-screen leading-7'>
        <h2 className='text-2xl font-semibold'>Something went wrong</h2>
        <p className='text-gray-600'>We'll get this fix asap.</p>
        <Link 
        to={home}
        //to={fallbackUrl}
        className='mt-2 btn bg-purple-600 px-6 cursor-pointer text-white-100 py-1'>
            Return Home</Link>

    </div>
  )
}

export default BuyerErrorFallback