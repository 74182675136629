import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../components/Loader';
import StoreCard from '../../../components/StoreCard'
import useStore from '../../../hooks/store/useStore';
import adBanner from '../../../assets/img/ad-banner2.png'
import ProductCategoryHeader from 'components/ProductCategoryHeader';
import FeaturedStore from 'components/FeaturedStore';
import ProductCategory from 'components/ProductCategory';
import SearchInput from 'components/SearchInput';
import FilterComponent from 'components/FilterComponent';
import { Popover } from "react-tiny-popover";
import FilterPill from "components/FilterPill";
import { ReactComponent as FilterIcon } from "assets/img/svgs/filter.svg";
import MarketplaceLayout from 'layouts/MarketplaceLayout';
import useCategory from 'hooks/category/usecategory';

function StoreListing() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [totalCount, setTotalCount] = useState(30);
  const { getStoreCategories } = useCategory();
  const { getStores,getCategorizedStore,getTopSellingStore,getfeaturedStores} = useStore();
  const { isLoading:loadingTopSelling, data:topSelling} = getTopSellingStore;
  const { isLoading:loadingFeaturedStore, data:featuredStores} = getfeaturedStores;
  const { isLoading:loadingCategorizeStore, data:categorizeStores} = getCategorizedStore;
  const { isLoading, data:categories} = getStoreCategories;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [productType, setProductType] = useState('All');
  //let isLoading=false;


  let timeout = useRef();
  const handleSearchInput = (event)=> {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      navigate(`/search?q=${event.target.value}`)
      //search(event.target.value);
    }, 2000);
  }

  const filterProducts = (type)=> {
    // queryParams.productsType = type=='All'? '':type;
    // // queryParams.searchProducts = searchTerm;
    // getProducts.refetch()
  }

  const filters = categories?.data?.storeCategories?.map((item=>item.categoryname));

  const handleFilter = (type)=> {
    // setProductType(type === 'All' ? 'All' : type);
    // filterProducts(type);
    navigate(`/marketplace/${type}`)
    setIsPopoverOpen(false);
  }

  return (
    <MarketplaceLayout categories={categorizeStores}>
      <main>
      {
        loadingTopSelling ||
        loadingFeaturedStore ||
        loadingCategorizeStore?(
        <div className='flex flex-col items-center justify-center my-auto h-80'><Loader/></div>):(
        <section className='pt-5 md:pt-0'>
        <div className='md:py-7 px-5 md:px-20  h-auto '>
           <div className='search flex gap-3 md:hidden'>
           <div className=' w-full md:w-1/2 '>
           <SearchInput onChange={handleSearchInput} placeholder='Search mudala'  />
           </div>
           <div className='filter md:hidden'>
           <div className="flex justify-between flex-wrap ">
        <div className="flex gap-4 xs:mb-4 md:mb-0">
          {/* <FilterPill title={productType} /> */}
          <Popover
            isOpen={isPopoverOpen}
            // isOpen={isPopoverOpen}
            positions={['bottom']} // if you'd like, you can limit the positions
            padding={10} // adjust padding here!
            reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
            onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
            content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
              <main className="">
              <div className="position-relative h-80 overflow-auto" style={{background: 'white', border: '1px solid var(--grey300)', padding: '10px',marginRight:'12rem', borderRadius: '8px',boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.1)' }}>
                {filters.map((filter, ind) => (
                  <div key={ind} 
                  className=" border border-b-2 text-base m-2 cursor-pointer hover:text-purple-600 " 
                  onClick={()=>{handleFilter(filter)}}>{filter}</div>
                  
                  
                ))}
              </div>
             
              </main>
            )}>
              <div className='cursor-pointer flex items-center justify-center w-14 h-11  filter-icon'
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              >
              <FilterIcon />
              </div>
           
          </Popover>
        </div>
        
      </div>
           </div>
           </div>
            <div className='ad-banner mt-2'>
              <Link to="/signup">
              <img src={adBanner} className="w-full " />
              </Link>
            </div>

        </div>

            <main className='mt-5 md:mt-0'>
              <ProductCategoryHeader title="Top Selling Stores" link="/top-selling-stores" />
              <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10 mt-2 md:my-10 px-5 md:px-20'>
            {
              topSelling?.data?.stores?.data?.map(store=>(
                <StoreCard store_name={store?.store?.store_name}
                store_banner={store?.store?.store_banner}
                store_description={store?.store?.store_description}
                merchant_code={store?.merchant_code}
                 />
              ))
            }
            
            </div>
            
            </main>
            <FeaturedStore stores={featuredStores} />
            <div className='md:py-7 px-5 md:px-20  h-auto'>
            <Link className='ad-banner mt-2' to="/signup">
            <Link to="/signup">
              <img src={adBanner} className="w-full " />
              </Link>
            </Link>

          </div>
            
            <ProductCategory stores={categorizeStores?.data?.stores} />
        </section>
       
        )
      }
      </main>
      
        
    </MarketplaceLayout>
  )
}

export default StoreListing

const store = {
  id:1,
  store_name:'Scent Uganda',
  store_description:'Perfumes, Beauty care, Cosmetics',
  merchantid:'scent-455',
  store_banner:'sample-logo.pn'
}