// ---------- import external dependencies -------------
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

// ----------- import internal dependencies -----------
import { instance } from "../../api/instance";

function useAuth() {
  /**
   * Handle merchant registration;
   */
  const registerMerchant = useMutation((data) => {
    return instance({
      url: "/register",
      method: "post",
      data: data,
    });
  });

  /**
   * Handle merchant login
   */
  const loginMerchant = useMutation((data) => {
    return instance({
      url: "/login",
      method: "post",
      data,
    });
  });

  /**
   * Handle logout
   */
  const logout = useMutation((data) => {
    return instance({
      url: "/logout",
      method: "post",
      data,
    });
  });

  const forgotPassword = useMutation((data) => {
    return instance({
      url: "/password/forgot",
      method: "post",
      data,
    });
  });

  const newPassword = useMutation((data) => {
    return instance({
      url: "/password/reset",
      method: "post",
      data,
    });
  });

  const socialMediaLogin = useMutation((data) => {
    return instance({
      url: "/login/loginFromSocial",
      method: "post",
      data,
    });
  });

  const verifyEmail = useMutation((data) => {
    return instance({
      url: "/email/verify",
      method: "post",
      data,
    });
  });

  const resendVerificationEmail = useQuery(
    "resendVerficationEmail",
    async (type) => {
      return instance({
        url: `/email/resend`,
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
      enabled: false // disable this query from automatically running

    }
  );

  const signout = ()=> {
    localStorage.clear();
    toast.success('Signin to continue');
  }

 const authCheckState = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        signout();
      } else {
        const expirationDate = new Date(localStorage.getItem("expirationDate"));
        console.log({expirationDate});
        if (expirationDate <= new Date()) {
          signout();
        }
      
    };
  };

  return {
    registerMerchant,
    loginMerchant,
    forgotPassword,
    newPassword,
    socialMediaLogin,
    logout,
    verifyEmail,
    resendVerificationEmail,
    authCheckState
  };
}

export default useAuth;
