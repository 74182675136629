// ------------ import external dependencies ----------
import { useMutation, useQuery } from "react-query";

// ------------ import internal dependencies -----------
import { instance } from "../../api/instance";

function useProfileInfo(code) {
  /**
   * modify product
   */
  const modifyProfile = useMutation((data) => {
    return instance({
      url: "/userAccount/modifyProfileInfo",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

    /**
   * update password
   */
  const updatePassword = useMutation((data) => {
    return instance({
      url: "/settings/updatePassword",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  /**
   * modify store
   */
  const modifyStore = useMutation((data) => {
    return instance({
      url: "/settings/updateStore",
      method: "post",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

    const getUserProfile = useQuery(
        'userProfile',
        async () => {
        return instance({
            url: `/userAccount/getProfileInfo`,
            method: "GET",
        });
        },
    );

    //  gets user invoices
    const getInvoices = useQuery(
        'invoices',
        async () => {
        return instance({
            url: `/billing/invoices`,
            method: "GET",
        });
        },
    );

    //  gets plans
    const getPlans = useQuery(
        'allPlans',
        async () => {
        return instance({
            url: `/subscriptions/all`,
            method: "GET",
        });
        },
    );

    //  get user plan
    const getUserPlan = useQuery(
        'userPlan',
        async () => {
        return instance({
            url: `/mySubscriptions`,
            method: "GET",
        });
        },
    );

    //  get user cards
    const getUserCards = useQuery(
        'userCards',
        async () => {
        return instance({
            url: `/myCards`,
            method: "GET",
        });
        },
    );

    /**
     * add new card
     */
    const addCard = useMutation((data) => {
      return instance({
        url: "/card/add",
        method: "post",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    });

    /**
     * modify card card
     */
    const modifyCard = useMutation((data) => {
        return instance({
        url: "/card/edit",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    /**
     * change default card
     */
    const setDefaultCard = useMutation((data) => {
        return instance({
        url: "/card/setDefault",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    /**
     * fetch withdrawal history
     */
    const getWithdrawalHistory = useQuery(
        'withdrawalHistory',
        async () => {
        return instance({
            url: `/funds/withdrawalHistory`,
            method: "GET",
        });
        },
    );

    /**
     * fetch deposits or payments
     */
    const getDeposits = useQuery(
        'deposits',
        async () => {
        return instance({
            url: `/funds/recentDeposits`,
            method: "GET",
        });
        },
    );

    /**
     * fetch account balance
     */
    const getBalance = useQuery(
        'accountBalance',
        async () => {
        return instance({
            url: `/funds/balance`,
            method: "GET",
        });
        },
    );

    const getBalanceOverTime = useQuery(
      'balanceOverTime',
      async () => {
      return instance({
          url: `/stats/balanceOverTime`,
          method: "GET",
      });
      },
  );

    /**
     * add bank details
     */
    const addBank = useMutation((data) => {
        return instance({
        url: "/userAccount/addBankAccount",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    /**
     * fetch notification settings
    */
    const getNotificationSettings = useQuery(
        'notificationSettings',
        async () => {
        return instance({
            url: `/userAccount/getNotificationSettings`,
            method: "GET",
        });
        },
    );

    const getBillingInvoices = useQuery(
      'BillingAndInvoices',
      async () => {
      return instance({
          url: `/billing/invoices`,
          method: "GET",
      });
      },
  );

    /**
     * update notification settings
     */
    const updateNotificationSettings = useMutation((data) => {
        return instance({
        url: "/settings/updateNotifications",
        method: "post",
        data,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        });
    });

    const withdrawFund = useMutation((data) => {
      return instance({
      url: "/funds/withdraw",
      method: "post",
      data,
      headers: {
          "Content-Type": "application/json",
      },
      });
  });

  const sendSupportEmail = useMutation((data) => {
    return instance({
    url: "/email/sendSupportEmail",
    method: "post",
    data,
    headers: {
        "Content-Type": "application/json",
    },
    });
});

  return {
    getUserProfile,
    modifyProfile,
    updatePassword,
    modifyStore,
    getInvoices,
    getPlans,
    getUserPlan,
    getUserCards,
    addCard,
    modifyCard,
    setDefaultCard,
    getWithdrawalHistory,
    getDeposits,
    getBalance,
    addBank,
    getNotificationSettings,
    updateNotificationSettings,
    getBalanceOverTime,
    getBillingInvoices,
    withdrawFund,
    sendSupportEmail
  };
}

export default useProfileInfo;
