import React from 'react';
// ------------- import external dependencies ------------
import styled from "styled-components";

// ---------- import internal dependencies ------------
import { ReactComponent as FilterIcon } from "../../assets/img/svgs/filter.svg";

const FilterComponent = React.forwardRef(({onClick}, ref)=> {
  return (
    <FilterWrapper onClick={onClick} ref={ref} className="cursor-pointer">
      <FilterIcon /> <span className="text-xs md:text-md"></span>
    </FilterWrapper>
  );
})

export default FilterComponent;

const FilterWrapper = styled.div`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: var(--ehite100);
  border: 1px solid var(--grey300);
  color: var(--grey700);
  min-height: 35px;
  min-width: 100px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.5rem;
`;
