import ProductCategoryHeader from 'components/ProductCategoryHeader'
import StoreCard from 'components/StoreCard'
import React from 'react'

const ProductCategory = ({stores}) => {
 
  return (
    <section>
        {
          Object.keys(stores).map(store=>(
            <main className='md:pt-1 pt-5'>
            <ProductCategoryHeader title={store} link={`/marketplace/${store}`} />
            <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-10 mt-2 md:my-10 px-5 md:px-20'>
                {
                  stores[store].map(item=>(
                    <StoreCard store_name={item?.store_name}
                    store_description={item?.store_description}
                    store_banner={item?.store_banner}
                    merchant_code={item?.merchant_code}
                     />
                  ))
                }
               
            </div>
            
        </main>
          ))
        }
   
    </section>
  )
}

export default ProductCategory

const store = {
    id:1,
    store_name:'Scent Uganda',
    store_description:'Perfumes, Beauty care, Cosmetics',
    merchantid:'scent-455',
    store_banner:'shop-banner.png'
  }