import React from 'react'

const TwitterIcon = ({width="20",height="16",color="#7F56D9",onClick}) => (
    <svg 
    onClick={onClick}
    width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.5475 3.98396C17.5552 4.15996 17.5552 4.32796 17.5552 4.50396C17.563 9.83998 13.6426 16 6.47319 16C4.35865 16 2.2827 15.368 0.5 14.184C0.808692 14.224 1.11738 14.24 1.42608 14.24C3.1779 14.24 4.88343 13.632 6.26483 12.504C4.59789 12.472 3.1316 11.344 2.62226 9.69598C3.20877 9.81598 3.81072 9.79198 4.3818 9.62398C2.56824 9.25598 1.26401 7.59997 1.2563 5.67197C1.2563 5.65597 1.2563 5.63997 1.2563 5.62397C1.79651 5.93597 2.40617 6.11197 3.02356 6.12797C1.31803 4.94396 0.78554 2.58396 1.81966 0.735951C3.80301 3.26396 6.72015 4.79196 9.85337 4.95996C9.53696 3.55996 9.96913 2.08796 10.9801 1.09595C12.5467 -0.432053 15.0162 -0.352052 16.498 1.27195C17.37 1.09595 18.2112 0.759951 18.9752 0.28795C18.682 1.22395 18.0723 2.01596 17.262 2.51996C18.0337 2.42396 18.79 2.20796 19.5 1.88795C18.9752 2.70396 18.3115 3.40796 17.5475 3.98396Z" 
fill={color}/>
</svg>

)

export default TwitterIcon