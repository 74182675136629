import React, {useState, useEffect} from 'react';
import Cart from '../../../components/Cart';
import { toast } from "react-toastify";

// ------------ import external dependencies ------------
import { Link, useNavigate } from "react-router-dom";

// ------------ import assets ------------
import BrandLogo from "../../../assets/img/logo.png";
import ShareIcon from "../../../assets/img/svgs/share.svg";
import SearchIcon from "../../../assets/img/svgs/search-dark.svg";
import UserIcon from "../../../assets/img/svgs/user.svg";
import CartIcon from "../../../assets/img/svgs/cart.svg";
import {ReactComponent as MenuIcon } from "../../../assets/img/svgs/menu-2.svg";
// ------  custom hooks ------
import useOrder from "../../../hooks/order/useOrder";
import SearchInput from 'components/SearchInput';

function Header({cartData, toggleCart, cart, storeIcon}) {
    const navigate = useNavigate();
    const [menu, toggleMenu] = useState(false);
    const home = localStorage.getItem('home')?localStorage.getItem('home'):'/';
    console.log(cartData)
    // checkout from store and proceed to payment
    const checkout = ()=> {
        if (cartData?.length > 0) {
            navigate('/buyer/merchant-store/checkout', {
                state: {
                    cart: cartData
                }
            })
        }
        else {
            toast.info("No items in cart");
        }
    }

    const nav = (route)=> {
        navigate(route);
    }
    return (
        <div className="py-7 px-5 md:px-20 border-0 border-b-2 border-solid border-grey-200 flex">
            <div className="w-1/2 md:w-1/4 flex">
                {/* <img src={SampleLogo} alt="sample logo" className="mr-auto text-sm" style={{height: '32px', width: '32px'}} /> */}
                {/* <img src={storeIcon ? storeIcon : BrandLogo} alt="sample logo" className="mr-auto text-sm" style={{height: '30px', width: '60px'}} /> */}
                <img src={storeIcon ? storeIcon : BrandLogo} alt="sample logo" className="mr-auto text-sm object-contain" style={{width: "90.42px",height: "32px"}} />
            </div>
            <div className="w-1/2 hidden md:flex">
                <div className="mx-auto flex space-x-10">
                    <Link to={'/'} className="basis-2 text-md text-grey-600 my-auto cursor-pointer" >
                        Home
                    </Link>
                    <span className="basis-2 text-md text-grey-600 my-auto cursor-pointer" onClick={checkout}>Checkout</span>
                    <Link to={'/buyer/support'} className="basis-2 text-md text-grey-600 my-auto cursor-pointer">Support</Link>
                </div>
            </div>
            <div className="w-1/2 md:w-1/4 flex">
                <div className="ml-auto flex space-x-5 md:space-x-10">
                    <img src={SearchIcon} alt="sample logo" className="basis-2 my-auto cursor-pointer" style={{height: '20px', width: '20px'}} />
                    <span className="flex cursor-pointer" onClick={()=>toggleCart(!cart)}>
                        <img src={CartIcon} alt="sample logo" className="basis-2 my-auto" style={{height: '20px', width: '20px'}} />
                        {cartData?.length > 0 ? (
                            <span className="flex p-px bg-red-600 place-content-center text-center justify-center" style={{height: '20px', width: '20px', borderRadius: '10px'}}>
                                <span className="my-auto text-xs text-white-100">{cartData?.length}</span>
                            </span>
                        ) : (null)}
                    </span>
                    <img src={UserIcon} onClick={()=> nav('/buyer/account')} alt="sample logo" className="basis-2 my-auto cursor-pointer" style={{height: '20px', width: '20px'}} />
                </div>
            </div>
        </div>
        
    )
}

export default Header
