import React, {useState} from 'react';
import styled from "styled-components";
import Accordion from '../../components/Accordion';
import { Link } from "react-scroll";
import {useNavigate} from 'react-router-dom';

// -------- importing assets --------
import Logo from "../../assets/img/logo.png";
import Chart from "../../assets/img/line-bar-chart.png";
import ProgressBars from "../../assets/img/progress-bars.png";
import SocialMock from "../../assets/img/social-screen-mock.jpg";
import DashboardMock from "../../assets/img/dashboard-mock.jpg";
import CTA from "../../assets/img/cta.jpg";
import {ReactComponent as Lines } from "../../assets/img/svgs/line-pattern.svg";
import {ReactComponent as MailPurpleIcon } from "../../assets/img/svgs/mail-purple.svg";
import {ReactComponent as BoltIcon } from "../../assets/img/svgs/bolt.svg";
import {ReactComponent as BarsIcon } from "../../assets/img/svgs/bars.svg";
import {ReactComponent as MenuIcon } from "../../assets/img/svgs/side-menu.svg";
import {ReactComponent as CloseIcon } from "../../assets/img/svgs/close.svg";
import {ReactComponent as CheckIcon } from "../../assets/img/svgs/circular-check.svg";
import {ReactComponent as ArrowIcon } from "../../assets/img/svgs/arrow.svg";
import { Questions } from '../../data';
import { currencyFormatter } from '../../utils/helper';

function Landing() {
    // component state managers
    const [menu, toggleMenu] = useState(false);

    const navigate = useNavigate();
    const nav = (route)=> {
        navigate(route)
    }

    return (
        <div className="w-full h-auto bg-grey-50 relative">
            {/* mobile menu */}
            {/* h-2/5 */}
            {menu ? (
                <div className="flex flex-col w-full h-full fixed top-0 right-0 z-40" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
                    <div className="w-3/5 h-full p-4 bg-white-100 ml-auto">
                        <div className="w-full flex justify-end">
                            <CloseIcon onClick={()=>toggleMenu(!menu)} className="cursor-pointer" />
                        </div>
                        <div className="w-full flex flex-col mt-4 px-4">
                            <p className="text-lg text-grey-100 font-medium my-2 cursor-pointer">Home</p>
                            <p className="text-lg my-2 w-full text-grey-100 font-medium my-auto cursor-pointer">
                                <Link activeClass="active" to="features" spy={true} smooth={true} offset={-100} duration={500} 
                                    onClick={()=>toggleMenu(!menu)}>
                                    Features
                                </Link>
                            </p>
                            <p className="text-lg my-2 w-full text-grey-100 font-medium my-auto cursor-pointer">
                                <Link activeClass="active" to="pricing" spy={true} smooth={true} offset={-100} duration={500}
                                    onClick={()=>toggleMenu(!menu)}>
                                    Pricing
                                </Link>
                                {/* Pricing */}
                            </p>
                            <p className="text-lg my-2 w-full text-grey-100 font-medium my-auto cursor-pointer">
                                <Link activeClass="active" onClick={()=> navigate("/marketplace")} spy={true} smooth={true} offset={-100} duration={500}
                                    >
                                    Marketplace
                                </Link>
                                {/* Store */}
                            </p>
                            <p className="text-lg my-2 w-full text-grey-100 font-medium my-auto cursor-pointer">
                                <Link activeClass="active" to="faq" spy={true} smooth={true} offset={-100} duration={500}
                                    onClick={()=>toggleMenu(!menu)}>
                                    FAQ
                                </Link>
                            </p>
                            <p className="text-lg my-2 w-full text-grey-100 font-medium cursor-pointer" onClick={()=> {
                                nav('/signin');
                            }}>
                                Login
                            </p>
                            <button className="bg-purple-600 border-0 text-white-100 text-base py-2 px-4 rounded-lg my-auto cursor-pointer" 
                                onClick={()=> {
                                    nav('/signup');
                                }}>
                                Sign up
                            </button>
                        </div>
                    </div>
                </div>
            ) : (null)}
            {/* header and overview section */} 
            <div className="w-full px-px md:px-10 py-5 pb-20 lg:bg-grey-200">
                <div className="px-0 md:px-10 flex w-full">
                    {/* mobile header */}
                    {/* {!menu ? ( */}
                        <div className="w-full flex md:hidden px-2 justify-between">
                            <img src={Logo} alt="logo" className="my-auto"/>
                            {/* <img src={Logo} alt="logo" className="my-auto" style={{width: '60px', height: '30px'}}/> */}
                            {/* <LogoIcon className="my-auto" /> */}
                            <MenuIcon className="cursor-pointer z-30" onClick={()=>toggleMenu(!menu)} />
                        </div>
                    {/* // ) : (null)} */}
                    {/* desktop header */}
                    <div className="hidden md:flex w-full">
                        <div className="flex w-4/5">
                            <img src={Logo} alt="logo" className="my-auto"/>
                            <p className="text-base text-grey-100 font-medium ml-8 my-auto cursor-pointer">Home</p>
                            <p className="text-base text-grey-100 font-medium ml-8 my-auto cursor-pointer">
                                <Link activeClass="active" to="features" spy={true} smooth={true} offset={-100} duration={500}>
                                    Features
                                </Link>
                            </p>
                            <p className="text-base text-grey-100 font-medium ml-8 my-auto cursor-pointer">
                                <Link activeClass="active" to="pricing" spy={true} smooth={true} offset={-100} duration={500}>
                                    Pricing
                                </Link>
                                {/* Pricing */}
                            </p>

                            <p className="text-base text-grey-100 font-medium ml-8 my-auto cursor-pointer">
                                <Link activeClass="active" onClick={()=> navigate("/marketplace")} spy={true} smooth={true} offset={-100} duration={500}>
                                Marketplace
                                </Link>
                                {/* Stores */}
                            </p>
                            
                            <p className="text-base text-grey-100 font-medium ml-8 my-auto cursor-pointer">
                                <Link activeClass="active" to="faq" spy={true} smooth={true} offset={-100} duration={500}>
                                    FAQ
                                </Link>
                            </p>
                        </div>
                        <div className="ml-auto flex w-1/5 justify-end">
                            <p className="text-base text-grey-100 font-medium my-auto cursor-pointer mr-[5%]" onClick={()=> {
                                nav('/signin');
                            }}>
                                Login
                            </p>
                            <button className="bg-purple-600 border-0 text-white-100 text-base py-2 px-4 rounded-lg my-auto cursor-pointer" 
                                onClick={()=> {
                                    nav('/signup');
                                }}>
                                Sign up
                            </button>
                        </div>
                    </div>
                </div>
                
                <div id="overview" className="px-0 md:px-2 mt-10 relative h-[650px] md:h-[750px]">
                    <div className="flex bg-purple-600 rounded-0 md:rounded-3xl px-10 md:px-24" style={{height: '450px'}}>
                        <div className="mx-auto my-auto w-full justify-center">
                            <p className="text-4xl md:text-7xl font-semibold text-white-100 text-center">The easiest way to sell online </p>
                            <p className="text-xl font-normal text-grey-200  text-center mt-6 md:mt-0">Create your online store, connect your social media pages, and sell your products faster.</p>
                        </div>
                    </div>
                    <div className="absolute z-20 bg-white-100 rounded-xl w-4/5 md:w-1/2 inset-x-[10%] md:inset-x-[25%] p-4 top-[65%] md:top-2/4" >
                        <div className="w-full">
                            <p className="text-lg font-normal text-black-50">Customers over time</p>
                        </div>
                        <div className="w-full">
                            <img src={Chart} alt="chart"/>
                        </div>
                        {/* <img src={ProgressBars} alt="progress" className="absolute left-2/3 md:left-3/4 top-2/4" style={{height: '200px', width: '200px'}} /> */}
                        {/* sm:h-[150px] sm:w-[150px] */}
                        <img src={ProgressBars} alt="progress" 
                        className="absolute h-[120px] w-[120px] lg:h-[200px] lg:w-[200px] left-[80%] sm:left-[85%] md:left-[80%] lg:left-[82%] 
                        top-[65%] sm:top-[75%] md:top-[60%] lg:top-[60%]" />
                    </div>
                </div>
                {/* lines on desktop */}
                <Lines className="absolute left-0 top-[7%] md:top-72 z-10 hidden md:block" />
                <Lines className="absolute right-[10%] top-[12%] z-10 h-[6%] hidden md:block" />
                {/* lines on mobile */}
                <Lines className="absolute left-0 top-[8%] z-10 block md:hidden" style={{height: '250px', width: '120px'}} />
                <Lines className="absolute right-0 top-[8%] z-10 h-[6%] block md:hidden" style={{height: '250px', width: '120px'}} />
            </div>
            {/* <div className="block sm:hidden ml-12"> <h2>xs</h2> </div>
            <div className="hidden sm:block md:hidden ml-12"> <h2>sm</h2> </div>
            <div className="hidden md:block lg:hidden ml-12"> <h2>md</h2> </div>
            <div className="hidden lg:block ml-12"> <h2>lg</h2> </div> */}
            {/* features section */}
            <div id="features" className="flex flex-col w-full px-0 md:px-2 py-5 bg-grey-50 pt-20 relative">
                <p className="text-purple-600 text-base font-semibold text-center">Features</p>
                <p className="text-3xl md:text-4xl font-semibold text-center mt-2">Beautiful analytics to grow smarter</p>
                <div className="w-full md:w-1/2 mx-2 md:mx-auto">
                    <p className="text-lg font-normal text-center mt-2 text-grey-500">Powerful, self-serve product and growth analytics to help you convert, engage, and retain more customers.</p>
                </div>
                {/* social */}
                <div className="flex flex-col md:flex-row w-100 px-0 md:px-10 pb-10 md:h-96 mt-10 md:mt-20 relative">
                    <div className="flex flex-col justify-center w-full md:w-1/2 px-10"> 
                        <div className="p-2 bg-grey-200 mb-4" style={{height: '40px', width: '40px', borderRadius: '50%'}}>
                            <MailPurpleIcon className="h-full w-full" />
                        </div>
                        <div>
                            <p className="text-2xl md:text-3xl font-semibold">Connect with social media profiles</p>
                            <p className="text-base md:text-lg font-normal text-grey-500 mt-2 mb-4 md:mb-px">
                                Whether you have a team of 2 or 200, our shared team inboxes keep everyone on the same page and in the loop. Leverage automation to move fast, while always giving customers a human, helpful experience.
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-0 md:px-10 h-full relative z-auto md:z-30"> 
                        <div className="h-full bg-grey-200 w-full rounded-0 md:rounded-3xl px-10 py-4 md:px-4 md:py4 mt-10 md:mt-0">
                            <img src={SocialMock} alt="social-mock" className="h-full w-full rounded-md"/>
                        </div>
                    </div>
                    <Lines className="hidden md:block absolute right-0 top-1/4 z-10 h-3/4"/>
                </div>
                {/* new orders */}
                <div className="flex flex-col md:flex-row w-100 px-0 md:px-10 pb-10 md:h-96 mt-10 relative">
                    <div className="w-full md:w-1/2 px-0 md:px-10 h-full relative z-30 order-2 md:order-1"> 
                        <div className="h-full bg-grey-200 w-full rounded-0 md:rounded-3xl px-10 py-4 md:px-4 md:py4 mt-10 md:mt-0">
                            <img src={DashboardMock} alt="social-mock" className="h-full w-full rounded-md"/>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center w-full md:w-1/2 px-10 order-1 md:order-2 mt-6 md:mt-0"> 
                        <div className="p-2 bg-grey-200 mb-4 ml-4 md:ml-0" style={{height: '40px', width: '40px', borderRadius: '50%'}}>
                            <BoltIcon className="h-full w-full" />
                        </div>
                        <div className="px-4">
                            <p className="text-2xl md:text-3xl font-semibold">Acknowledge new orders instantly</p>
                            <p className="text-base md:text-lg font-normal text-grey-500 mt-2 mb-4 md:mb-px">
                                Keep your customers in the flow by embedding help articles right on your website. With Beacon, they never have to leave the page to find an answer.
                            </p>
                        </div>
                    </div>
                    <Lines className="hidden md:block absolute -left-20 top-1/4 z-10 h-3/4"/>
                </div>
                
                {/* reports */}
                <div className="flex flex-col md:flex-row w-100 px-0 md:px-10 pb-10 md:h-96 mt-10 md:mt-20 relative">
                    <div className="flex flex-col justify-center w-full md:w-1/2 px-10"> 
                        <div className="p-2 bg-grey-200 mb-4" style={{height: '40px', width: '40px', borderRadius: '50%'}}>
                            <BarsIcon className="h-full w-full" />
                        </div>
                        <div>
                            <p className="text-2xl md:text-3xl font-semibold">Manage your orders and sales with reports</p>
                            <p className="text-base md:text-lg font-normal text-grey-500 mt-2 mb-4 md:mb-px">
                                Measure what matters with Mudala’s easy-to-use reports. You can filter, export, and drilldown on the data in a couple clicks..
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 px-0 md:px-10 h-full relative z-auto md:z-30"> 
                        <div className="h-full bg-grey-200 w-full rounded-0 md:rounded-3xl px-10 py-4 md:px-4 md:py4 mt-10 md:mt-0">
                            <img src={DashboardMock} alt="social-mock" className="h-full w-full rounded-md"/>
                        </div>
                    </div>
                    <Lines className="hidden md:block absolute right-0 -top-[15%] z-10 h-3/4"/>
                </div>
            </div>
            {/* CTA */}
            <div id="cta" className="flex flex-col md:flex-row w-full md:h-[500px] px-8 md:px-0">
                <div className="flex flex-col justify-center w-full md:w-[60%] h-full pl-0 md:pl-20 pr-0 md:pr-0">
                    <p className="text-3xl md:text-5xl font-semibold">Join 4,000+ businesses growing with Mudala</p>
                    <div className="flex mt-4">
                        <CheckIcon />
                        <p className="text-lg font-normal text-grey-500 ml-2 my-auto">14-day free trial</p>
                    </div>
                    <div className="flex mt-4">
                        <CheckIcon />
                        <p className="text-lg font-normal text-grey-500 ml-2 my-auto">Peronalized onboarding</p>
                    </div>
                    <div className="flex mt-4">
                        <CheckIcon />
                        <p className="text-lg font-normal text-grey-500 ml-2 my-auto">Access to all features</p>
                    </div>
                </div>
                <div className="w-full md:w-[40%] mt-4 md:mt-0">
                    <img src={CTA} alt="social-mock" className="h-[240px] md:h-full w-full md:w-full ml-auto"/>
                </div>
            </div>
            {/* pricing */}
            <div id="pricing" className="px-6 md:px-20 py-10 w-full mt-px md:mt-12">
                <p className="text-sm md:text-base text-purple-600 font-semibold ml-2 md:ml-0">Plans</p>
                <p className="text-4xl md:text-5xl font-semibold mt-5">Simple, transparent pricing</p>
                <p className="text-lg font-normal text-grey-500 mt-4">You can start your free trial or subscrible immediately to one of our two plans.</p>
                <div className="flex flex-col md:flex-row justify-between my-10 md:h-60">
                    <Card className="w-full md:w-1/3 flex flex-col justify-center h-[250px] md:h-full">
                        <p className="text-2xl lg:text-3xl font-semibold text-black-100 text-center mt-10 md:mt-4">{currencyFormatter.format(0)}</p>
                        <p className="text-sm font-semibold text-black-100 text-center mt-4">Starter plan</p>
                        <p className="text-base text-grey-500 text-center">Free for two weeks</p>
                        <button className="bg-purple-600 border-0 text-white-100 text-base py-2 rounded-lg mt-auto mx-4 cursor-pointer" 
                            onClick={()=> {
                                nav('/signup');
                            }}
                        >
                            Start Free Trial
                        </button>
                    </Card>
                    <Card className="w-full md:w-1/3 flex flex-col justify-center h-[250px] md:h-full mx-0 my-10 md:my-0 md:mx-5 relative hover:shadow-2xl">
                        <div className="absolute -top-[30px] -right-[4%] sm:right-[20%] md:-right-[80px] w-[150px]">
                            <p className="text-sm text-purple-600 absolute right-0 -top-2 font-medium">Most popular!</p>
                            <ArrowIcon className="animate-bounce" />
                        </div>
                        <p className="text-2xl lg:text-3xl font-semibold text-black-100 text-center mt-10 md:mt-4">{currencyFormatter.format(2500)}/week</p>
                        <p className="text-sm font-semibold text-black-100 text-center mt-4">Basic plan</p>
                        <p className="text-base text-grey-500 text-center">Billed Weekly</p>
                        <button className="bg-purple-600 border-0 text-white-100 text-base py-2 rounded-lg mt-auto mx-4 cursor-pointer" 
                            onClick={()=> {
                                nav('/signup');
                            }}
                        >
                            Get Started
                        </button>
                    </Card>
                    <Card className="w-full md:w-1/3 flex flex-col justify-center h-[250px] md:h-full">
                        <p className="text-2xl lg:text-3xl font-semibold text-black-100 text-center mt-10 md:mt-4">{currencyFormatter.format(7000)}/month</p>
                        <p className="text-sm font-semibold text-black-100 text-center mt-4">Business plan</p>
                        <p className="text-base text-grey-500 text-center">Billed Monthly</p>
                        <button className="bg-purple-600 border-0 text-white-100 text-base py-2 rounded-lg mt-auto mx-4 cursor-pointer" 
                            onClick={()=> {
                                nav('/signup');
                            }}
                        >
                            Get Started
                        </button>
                    </Card>
                </div>
            </div>
            {/* FAQs */}
            <div id ="faq" className="flex w-full py-20 " style={{background: "#1D2939"}}>
                <div className="flex flex-col w-full md:w-1/2 mx-0 md:mx-auto">
                    <p className="text-2xl md:text-3xl font-semibold text-center text-white-100">Frequently asked questions</p>
                    <p className="text-base md:text-lg font-normal text-grey-500 mt-2 text-center">Everything you need to know about the product and billing.</p>
                    <div className="w-100 flex flex-col mx-4 md:mx-0">
                        {
                            Questions.map(item=>(
                                <Accordion heading={item.title}
                        content={item.text} />
                            ))
                        }
                        
                    </div>
                </div>
            </div>
            {/* start trial */}
            <div className="p-10 md:p-20 w-full">
                <div className="bg-grey-400 p-8 md:p-20 flex flex-col rounded-2xl">
                    <p className="text-2xl md:text-3xl font-semibold text-center">Start your 15-day free trial</p>
                    <p className="text-lg md:text-xl font-normal text-grey-100 text-center mt-10 md:mt-0">Join over 4,000+ businesses already growing with Mudala.</p>
                    <button className="bg-purple-600 border-0 text-white-100 text-base py-2 rounded-lg mt-6 mx-auto cursor-pointer" 
                        onClick={()=> {
                            nav('/signup');
                        }}
                    >
                        Get Started
                    </button>
                </div>
            </div>
            {/* footer */}
            <footer className="w-full flex flex-col py-10" style={{background: "#1D2939"}}>
                <div className="flex w-full">
                    {/* <img src={Logo} alt="logo" className="mx-4 md:mx-auto" style={{width: '60px', height: '30px'}}/> */}
                    <img src={Logo} alt="logo" className="mx-4 md:mx-auto"/>
                    {/* <LogoIcon className="mx-4 md:mx-auto" /> */}
                </div>
                <div className="flex flex-col md:flex-row mx-4 md:mx-auto mt-4">
                    <p className="text-base text-white-100 font-medium ml-0 md:ml-8 my-2 md:my-auto cursor-pointer">
                        <Link activeClass="active" to="overview" spy={true} smooth={true} offset={-100} duration={500}>
                            Overview
                        </Link>
                    </p>
                    <p className="text-base text-white-100 font-medium ml-0 md:ml-8 my-2 md:my-auto cursor-pointer">
                        <Link activeClass="active" to="features" spy={true} smooth={true} offset={-100} duration={500}>
                            Features
                        </Link>
                    </p>
                    <p className="text-base text-white-100 font-medium ml-0 md:ml-8 my-2 md:my-auto cursor-pointer">
                        <Link activeClass="active" to="pricing" spy={true} smooth={true} offset={-100} duration={500}>
                            Pricing
                        </Link>
                    </p>
                    <p className="text-base text-white-100 font-medium ml-0 md:ml-8 my-2 md:my-auto cursor-pointer">
                        <Link activeClass="active" to="faq" spy={true} smooth={true} offset={-100} duration={500}>
                            FAQs
                        </Link>
                    </p>
                </div>
                <div className="w-full px-4 md:px-20 mt-10">
                    <div className="w-full bg-grey-600" style={{height: '1px'}} />
                    <div className="flex flex-col md:flex-row w-full my-6">
                        <p className="text-grey-600 order-2 md:order-1 mt-4 md:mt-0">© 2022 Mudala. All rights reserved.</p>
                        <div className="flex ml-0 md:ml-auto order-1 md:order-2">
                            <p className="text-base text-grey-600">Terms</p>
                            <p className="text-base text-grey-600 mx-4">Privacy</p>
                            <p className="text-base text-grey-600">Cookies</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

const Card = styled.div`
border: 1px solid var(--grey200);
padding: 0.8rem;
border-radius: 8px;
box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
  0px 1px 2px rgba(16, 24, 40, 0.06);
background-color: var(--white100);
`;

export default Landing
