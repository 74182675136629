// -------------- import external dependencies ------------
import { lazy } from "react";

// -------------- import internal dependencies --------------
// ------ import auth pages -------
const SignUp = lazy(() => import("../screens/auth/signup"));
const Otp = lazy(() => import("../screens/auth/otp"));
const VerifyEmail = lazy(() => import("../screens/auth/verifyComplete"));
const EmailVerification = lazy(() => import("../screens/auth/emailVerification"));
const SignIn = lazy(() => import("../screens/auth/signin"));
const ForgotPassword = lazy(() => import("../screens/auth/forgotPassword"));
const PasswordVerification = lazy(() =>
  import("../screens/auth/passwordVerification")
);
const NewPassword = lazy(() => import("../screens/auth/newPassword"));
const PasswordSuccess = lazy(() => import("../screens/auth/passwordSuccess"));
const Onboarding = lazy(() => import("../screens/auth/onboarding"));
const ProductSuccess = lazy(() =>
  import("../screens/auth/onboarding/products")
);
const ProductPricing = lazy(() => import("../screens/auth/onboarding/pricing"));
const Deliveries = lazy(() => import("../screens/auth/onboarding/deliveries"));
const StorePreview = lazy(() => import("../screens/auth/onboarding/StorePreview"));
const Dashboard = lazy(() => import("../screens/app/dashboard"));
const Reports = lazy(() => import("../screens/app/report"));
const Products = lazy(() => import("../screens/app/products"));
const Orders = lazy(() => import("../screens/app/orders"));
const Funds = lazy(() => import("../screens/app/funds"));
const Issues = lazy(() => import("../screens/app/issues"));
const Activity = lazy(() => import("../screens/app/activity"));
const AddProduct = lazy(() => import("../screens/app/addproduct"));
const ProductDetails = lazy(() => import("../screens/app/productDetails"));
const EditProduct = lazy(() => import("../screens/app/editProduct"));
const Settings = lazy(() => import("../screens/app/settings"));
const TrackOrderMerchant = lazy(() => import("../screens/app/trackOrder"));

// buyer routes
const Account = lazy(() => import("../screens/app/Buyer/Account/index"));
const Store = lazy(() => import("../screens/app/Buyer/store/index"));
const ViewProduct = lazy(() => import("../screens/app/Buyer/store/product"));
const Checkout = lazy(() => import("../screens/app/Buyer/checkout"));
const Support = lazy(() => import("../screens/app/Buyer/support"));
const CheckoutPayment = lazy(() => import("../screens/app/billing/CheckoutPayment"));
const Confirm = lazy(() => import("../screens/app/billing/Confirm"));
const TrackOrder = lazy(() => import("../screens/app/billing/TrackOrder"));


// --------- auth routes --------
export const authRoutes = [
  {
    path: "/signup",
    component: SignUp,
    title: "Sign Up",
  },
  {
    path: "/otp",
    component: Otp,
    title: "Otp Verification",
  },
  {
    path: "/signup-success",
    component: VerifyEmail,
    title: "Signup Success",
  },
  {
    path: "/signin",
    component: SignIn,
    title: "Sign In",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    title: "Forgot Password",
  },
  {
    path: "/verify-password",
    component: PasswordVerification,
    title: "Verify password",
  },
  {
    path: "/verify-email/:id/:token",
    component: EmailVerification,
    title: "Email Verification",
  },
  {
    path: "/new-password",
    component: NewPassword,
    title: "New password",
  },
  {
    path: "/password-success",
    component: PasswordSuccess,
    title: "Password success",
  },
  {
    path: "/onboarding",
    component: Onboarding,
    title: "Onboarding",
  },
];

// ------- product routes -------
export const productRoutes = [
  {
    path: "/product-success",
    component: ProductSuccess,
    title: "Product Success",
  },
  {
    path: "/product-pricing",
    component: ProductPricing,
    title: "Product Pricing",
  },
  {
    path: "/deliveries-detail",
    component: Deliveries,
    title: "Deliveries Detail",
  },
  {
    path: "/store-preview",
    component: StorePreview,
    title: "Preview Store",
  },
];

// ------ Buyer routes --------
export const buyerRoutes = [
  {
    path: "/account",
    component: Account,
    title: "Account",
  },
  {
    path: "/merchant-store",
    component: Store,
    title: "Store",
  },
  {
    path: "/merchant-store/product",
    component: ViewProduct,
    title: "Product Details",
  },
  {
    path: "/merchant-store/checkout",
    component: Checkout,
    title: "Checkout",
  },
  {
    path: "/support",
    component: Support,
    title: "Support",
  },
  {
    path: "/billing/checkout-payment",
    component: CheckoutPayment,
    title: "Initiate Payment",
  },
  {
    path: "/billing/confirm",
    component: Confirm,
    title: "Payment Confirmation",
  },

  {
    path: "/order/track",
    component: TrackOrder,
    title: "Order Tracking",
  },
  // {
  //   path: "/buyer/profile",
  //   component: Profile,
  //   title: "Profile",
  // },
];

// ------ merchant routes --------
export const merchantRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    title: "Merchant Dashboard",
  },
  {
    path: "/reports",
    component: Reports,
    title: "Reports",
  },
  {
    path: "/products",
    component: Products,
    title: "products",
  },
  {
    path: "/product/add",
    component: AddProduct,
    title: "Add new product",
  },
  {
    path: "/product/details",
    component: ProductDetails,
    title: "Product Info",
  },
  {
    path: "/product/edit",
    component: EditProduct,
    title: "Edit product",
  },
  {
    path: "/orders",
    component: Orders,
    title: "Orders",
  },
  {
    path: "/funds",
    component: Funds,
    title: "funds",
  },
  {
    path: "/issues",
    component: Issues,
    title: "Issues",
  },
  {
    path: "/settings",
    component: Settings,
    title: "Product settings",
  },
  {
    path: "/activities",
    component: Activity,
    title: "Activities",
  },
  {
    path: "/orders/track",
    component: TrackOrderMerchant,
    title: "Track Order",
  },
];

export const billingRoute = [
  {
    path: "/billing/checkout-payment",
    component: CheckoutPayment,
    title: "Initiate Payment",
  },
]