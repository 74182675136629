// ----------- import external dependencies -----------
import React from "react";
import ReactDOM from "react-dom/client";

// -------------- import internal dependencies -------------
import App from "./App";
import { ShoppingCartProvider } from "./context/cart/cartContext";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
 
    <ShoppingCartProvider>
    <App />
    </ShoppingCartProvider>
    
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
