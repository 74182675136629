import React from 'react'

const SendInvite = ({width="26",height="26"}) => (
    <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.9844 5.9043C21.3965 7.59598 23.0608 10.2856 23.3985 13.3891" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.73438 13.4496C3.04795 10.3583 4.68816 7.66869 7.07612 5.96484" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.40625 23.8809C9.80526 24.5989 11.3972 25.0005 13.0736 25.0005C14.6897 25.0005 16.2093 24.6354 17.5722 23.9661" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.0715 7.76672C14.9232 7.76672 16.4243 6.25194 16.4243 4.38336C16.4243 2.51478 14.9232 1 13.0715 1C11.2198 1 9.71875 2.51478 9.71875 4.38336C9.71875 6.25194 11.2198 7.76672 13.0715 7.76672Z" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.35279 22.6398C6.20448 22.6398 7.70558 21.125 7.70558 19.2564C7.70558 17.3878 6.20448 15.873 4.35279 15.873C2.5011 15.873 1 17.3878 1 19.2564C1 21.125 2.5011 22.6398 4.35279 22.6398Z" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.6497 22.6398C23.5014 22.6398 25.0025 21.125 25.0025 19.2564C25.0025 17.3878 23.5014 15.873 21.6497 15.873C19.798 15.873 18.2969 17.3878 18.2969 19.2564C18.2969 21.125 19.798 22.6398 21.6497 22.6398Z" stroke="#6941C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

)

export default SendInvite