// ------------- import external dependencies ----------
import React,{ Suspense,lazy} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";


// -------------- import internal dependencies --------------
import AuthRoutes from "./routes/AuthRoutes";
import DashboardRoutes from "./routes/DashboardRoutes";
import BuyerRoutes from "./routes/BuyerRoutes";
import Toaster from "./components/Notifier";

// ------- import routes config --------
import { authRoutes, productRoutes, merchantRoutes, buyerRoutes, billingRoute } from "./routes/routes";

// -------- importing landing page --------
import Landing from "./screens/app/Landing";
import FallbackLoader from './components/FallbackLoader';

import "react-dates/lib/css/_datepicker.css";
import "./css/main.css";
import StoreListing from "./screens/app/marketplace";
import Search from "screens/app/marketplace/Search";
const Waitlist = lazy(()=>import('./screens/auth/Waitlist'))
const Invite = lazy(()=>import('./screens/auth/InviteSuccess'))
const Referral = lazy(()=>import('./screens/auth/Referral'))
const FeaturedStores = lazy(()=>import('./screens/app/marketplace/FeatureStore'))
const ProductCategories = lazy(()=>import('./screens/app/marketplace/ProductCategories'))
const TopSellingStores = lazy(()=>import('./screens/app/marketplace/TopSellingStores'))


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, { message: status }) => status !== "500",
    },
  },
});

function App() {
  const token = localStorage.getItem("accessToken");
  if(token){
    
  }

  const errorHandler = ({error,errorInfo}) => {
      console.log({errorInfo});
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Suspense 
          fallback={
            <div className="h-screen w-screen flex justify-center items-center">
              <div className="h-32 w-32 animate-spin" style={{border: "16px solid #f3f3f3", borderTop: "16px solid #7F56D9", borderRadius: "50%" }}>
              </div>
            </div>
          }
        >
         
          <Router>
            <Routes>
            
              {/* <Route path="/" element={<Navigate to="/landing" />} /> */}
              <Route path="/" element={<Landing />} />
              <Route path="/wait-list" element={<Waitlist />} />
              <Route path="/invite-success" element={<Invite />} />
              <Route path="/referral" element={<Referral />} />
              <Route path="/marketplace" element={<StoreListing />} />
              <Route path="/marketplace/:category" element={<ProductCategories />} />
              <Route path="/featured-stores" element={<FeaturedStores />} />
              <Route path="/top-selling-stores" element={<TopSellingStores />} />
              <Route path="/search" element={<Search />} />
              
              <Route path="/*" element={<AuthRoutes routes={authRoutes} />} />
              
             
              <Route
                path="/merchant/*"
                element={<DashboardRoutes routes={merchantRoutes} />}
              />
             
              <Route
                path="/buyer/*"
                element={<BuyerRoutes routes={buyerRoutes} />}
              />
              {productRoutes.map((route, i) => (
                <Route
                  path={route.path}
                  key={i}
                  element={<route.component />}
                />
              ))}
              {billingRoute.map((route,i)=>(
                <Route
                path={route.path}
                key={i}
                element={<route.component />}
              />
              ))}
           <Route path="*" element={<p>404</p>} />
              
            </Routes>
            
           

       
            <Toaster />
          </Router>
          
        </Suspense>
      </QueryClientProvider>
    </>
  );
}

export default App;
