// ----------- import external dependencies -----------
import { useMutation, useQuery } from "react-query";

// ---------- import internal dependencies -----------
import { instance } from "../../api/instance";
import {queryParams} from "./queryparam";

function useStore() {
  /**
   * get stores categories
   */
  const getMerchantStore = useQuery(
    "getStore",
    async () => {
      return instance({
        url: "/merchant/getStores",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
      enabled: false // disable this query from automatically running
    }
  );

  const getStores = useQuery(
    "getAllStores",
    async (type) => {
      return instance({
        url: `/marketplace/getAllStores?&page=${queryParams.pageNum ?? '1'}`,
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
      refetchOnWindowFocus: false,
     
    }
  );

  const getStorePreview = useQuery(
    "getStorePreview",
    async () => {
      return instance({
        url: "/previewStore",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    }
  );

  const getTopSellingStore = useQuery(
    "TopSellingStores",
    async () => {
      return instance({
        url: "/marketplace/getTopSellingStores",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    }
  );

  const getCategorizedStore = useQuery(
    "CategorizeStores",
    async () => {
      return instance({
        url: "/marketplace/stores/categorize",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    }
  );

  const getfeaturedStores = useQuery(
    "FeaturedStores",
    async () => {
      return instance({
        url: "/marketplace/getFeaturedStores",
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
    }
  );

  const getStoresByCategory = useMutation((data) => {
    return instance({
      url: "/marketplace/getStoresByCategory",
      method: "post",
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  });

  // get all products
  const searchStores = useQuery(
    "searchStore",
    async (type) => {
      return instance({
        url: `/marketplace/search?keyword=${queryParams.keyword}&page=${queryParams.pageNum ?? '1'}`,
        method: "GET",
      });
    },
    {
      retry: (count, { message: status }) => status !== "500",
      refetchOnWindowFocus: false,
      enabled: false // disable this query from automatically running
    }
  );



  return {
    getMerchantStore,
    getStores,
    getStorePreview,
    getTopSellingStore,
    getCategorizedStore,
    getfeaturedStores,
    getStoresByCategory,
    searchStores
  };
}

export default useStore;
