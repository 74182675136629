import React from 'react';
import {ReactComponent as OpenIcon } from "../../assets/img/svgs/accordion-open.svg";
import {ReactComponent as CloseIcon } from "../../assets/img/svgs/accordion-close.svg";

function Accordion({heading, content}) {
    const [open, toggleOpen] = React.useState(false);
    const [classes, setClasses] = React.useState("w-full flex-col p-4 mt-6 cursor-pointer");
    React.useEffect(()=> {
        if(open) {
            setClasses("w-full bg-grey-600 flex p-4 rounded-2xl mt-6 cursor-pointer");   
        }
        else {
            setClasses("w-full flex p-4 mt-6 cursor-pointer");   
        }
    }, [open])
    return (
        <div className={classes} onClick={()=> toggleOpen(!open)}>
            <div className="flex mt-px">
                {open ? (
                    <CloseIcon />
                ) : (<OpenIcon />)}
            </div>
            <div>
                <p className="text-lg text-white-100 font-medium ml-4">{heading}</p>
                {open ? (
                    <p className="text-sm font-normal text-white-200 ml-4" style={{color: "#EAECF0"}}>{content}</p>
                ) : (null)}
            </div>
        </div>
    )
}

export default Accordion;
