import React from 'react'
import { Link } from 'react-router-dom'
import shopBanner from '../../assets/img/shop-banner.png'

function StoreCard({store_banner,store_name,store_description,merchant_code}) {
  
    return (
        <div className='bg-white-100 flex md:flex-row flex-col gap-1 md:gap-3 cursor-pointer h-auto  hover:shadow-md my-2 md:my-0 border__sm md:rounded-none md:border-0' 
        >
            <div className='image__container w-full md:w-28 h-[131px] md:h-[101px] bg-gray-50'>
                <img src={store_banner}
                    className='w-full md:w-[150px] md:h-[101px] h-[131px] object-cover border__sm__img md:rounded-none '/>
            </div> 
        <div className='store__information leading-6 md:leading-8 px-2 py-2 mt-2 md:mt-0 flex flex-col'>
            <h3 className='text-md md:text-xl text-[#333333] '>{store_name}</h3>
                <p className='text-[9px] md:text-xs text-[#333333]'>
                        {store_description?.substring(0,30)}
                        
                </p>
                <p className='mt-2'><Link 
                        className='text-[#7F56D9] text-base'
                        to={`/buyer/merchant-store?code=${merchant_code}`}>
                            Visit store
                </Link></p>
        </div>

                </div>
  )
}

export default StoreCard